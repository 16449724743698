import React, { Component } from 'react'
import { graphql } from 'gatsby'
import IdeasListingLayout from '../../layouts/ideas/IdeasListingLayout'
import VirtualizedPostsList from '../../components/virtualized-posts-list/VirtualizedPostsList'
import SEO from '../../components/seo'

export default class IdeasStories extends Component {
    render() {
        const { page, stories } = this.props.data
        return (
            <IdeasListingLayout>
                <SEO
                    yoast={page.yoast}
                    title={page.title}
                    description={page.content}
                    dateModified={page.modified}
                    datePublished={page.date}
                />
                <section className="IdeasStories container-fluid px-yd">
                    <div className="ul-ideas">
                        <VirtualizedPostsList posts={stories.edges} />
                    </div>
                </section>
            </IdeasListingLayout>
        )
    }
}

export const pageQuery = graphql`
    query($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            slug
            title
            content
            ...YoastPage
        }
        stories: allWordpressPost {
            edges {
                node {
                    ...IdeasStoryListItemFragment
                }
            }
        }
    }
`
